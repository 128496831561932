<template>
	<div>
		<v-stepper
			class="elevation-0"
			v-model="e6"
			vertical
			>
			<v-stepper-step
				:complete="e6 > 1"
				step="1"
				@click="e6 = 1"
				>
				<span class="stepper-step">Account Information</span>
			</v-stepper-step>

			<v-stepper-content step="1">
				<v-card
					:elevation="0"
					class="mb-6"
					>
					<account-information :disabled="true" />
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="$router.push({path: '/sales-prospecting'})"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 2"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				:complete="e6 > 2"
				step="2"
				@click="e6 = 2"
				>
				<span class="stepper-step">Sales Presentation Information</span>
			</v-stepper-step>

			<v-stepper-content step="2">
				<v-card
					:elevation="0"
					class="mb-6"
					>
					<sales-presentation-information :disabled="true" />
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 3"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				:complete="e6 > 3"
				step="3"
				@click="e6 = 3"
				>
				<span class="stepper-step">Score Card</span>
			</v-stepper-step>

			<v-stepper-content step="3">
				<v-card
					:elevation="0"
					class="mb-6"
					>
				<score-card :update="true" :disabled="true" />
				</v-card>
				<v-divider></v-divider>
				<v-row class="mt-2">
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>
						<v-btn
							@click="e6 = 4"
							color="info"
							style="float:right;"
							>
							<b>Continue</b>
						</v-btn>
					</v-col>
				</v-row>
			</v-stepper-content>

			<v-stepper-step
				:complete="e6 > 4"
				step="4"
				@click="e6 = 4"
				>
				<span class="stepper-step">Supporting Documents</span>
			</v-stepper-step>

			<v-stepper-content step="4">
				<v-card
					class="mb-1"
					:elevation="0"
					>
					<supporting-documents :disabled="true" />

					<br>

					<v-textarea
						v-if="this.currUser.is_president === '1' && !$route.query.viewOnly"
						label="Comment"
						hide-details="auto"
						outlined
						dense
						@change="change"
					></v-textarea>

					<v-textarea
						v-if="this.currUser.is_evaluation_committee === '1' && this.salesProspectingForm.status == 'Initial Evaluation Committee Approval Pending' && this.salesProspectingForm.evaluation_status.eval_pending.includes(this.currUser.email) && !$route.query.viewOnly"
						label="Comment"
						hide-details="auto"
						outlined
						dense
						@change="change"
					></v-textarea>
					
				</v-card>
				<v-divider class="my-5"></v-divider>
				<v-row v-if="!$route.query.viewOnly">
					<v-col>
						<v-btn
							color="primary"
							@click="e6 -= 1"
							outlined
							>
							<b>Back</b>
						</v-btn>

					<section v-if="this.currUser.is_president === '1'">
						<v-btn
							v-if="comment"
							class="mr-4"
							color="primary"
							style="float:right;"
							@click.prevent="approve"
							>
							<b>Approve SP</b>
						</v-btn>
						<v-btn
							v-else
							disabled
							class="mr-4"
							color="primary"
							style="float:right;"
							@click.prevent="approve"
							>
							<b>Approve SP</b>
						</v-btn>
					</section>

					<section v-if="this.currUser.is_president === '1'">
						<v-btn
							v-if="comment"
							class="mr-4"
							outlined
							color="error"
							style="float:right;"
							@click="disapproveSp"
							>
							<b>Disapprove SP</b>
						</v-btn>
						<v-btn
							v-else
							disabled
							class="mr-4"
							outlined
							color="error"
							style="float:right;"
							>
							<b>Disapprove SP</b>
						</v-btn>
					</section>

					<section v-if="this.currUser.is_evaluation_committee === '1' && this.salesProspectingForm.status == 'Initial Evaluation Committee Approval Pending' && this.salesProspectingForm.evaluation_status.eval_pending.includes(this.currUser.email)">
						<v-btn
							v-if="comment"
							class="mr-4"
							color="primary"
							style="float:right;"
							@click.prevent="approve"
							>
							<b>Approve SP</b>
						</v-btn>
						<v-btn
							v-else
							disabled
							class="mr-4"
							color="primary"
							style="float:right;"
							@click.prevent="approve"
							>
							<b>Approve SP</b>
						</v-btn>
					</section>

					<section v-if="this.currUser.is_evaluation_committee === '1' && this.salesProspectingForm.status == 'Initial Evaluation Committee Approval Pending' && this.salesProspectingForm.evaluation_status.eval_pending.includes(this.currUser.email)">
						<v-btn
							v-if="comment"
							class="mr-4"
							outlined
							color="primary"
							style="float:right;"
							@click="returnSP"
							>
							<b>Return SP</b>
						</v-btn>
						<v-btn
							v-else
							disabled
							class="mr-4"
							outlined
							color="primary"
							style="float:right;"
							>
							<b>Return SP</b>
						</v-btn>
					</section>
					
					</v-col>
				</v-row>
			</v-stepper-content>
		</v-stepper>		
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import jsonToFormData from 'json-form-data';
import ScoreCard from '../components/modules/scorecard/ScoreCard.vue';
import AccountInformation from '../components/modules/salesProspecting/AccountInformation.vue';
import SalesPresentationInformation from '../components/modules/salesProspecting/SalesPresentationInformation.vue'
import SupportingDocuments from '../components/modules/salesProspecting/SupportingDocuments.vue'

export default {
	components: {
		ScoreCard: ScoreCard,
		AccountInformation: AccountInformation,
		SalesPresentationInformation: SalesPresentationInformation,
		SupportingDocuments: SupportingDocuments
	},
	async created () {
		const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
		this.assignData(resSP.data.result[0])
	},
	data: () => ({ 
		e6: 1
	}),
	computed: {
		...mapGetters({
			salesProspectingForm: 'salesProspecting/salesProspectingForm',
			spNumber: 'salesProspecting/spNumber',
			initialUploaded: 'salesProspecting/initialUploaded',
			currUser: 'auth/currUser',
			comment: 'salesProspecting/comment'
		}),
	},
	methods: {
		async disapproveSp(){
			var res = ''
			const initialPayload = { comment: this.comment}
			const payload = {id: this.$route.params.id, payload: initialPayload}

			res = await this.$store.dispatch('salesProspecting/doDisapproveSPByPresident', {payload})
			if (res.status === 200) {
				this.$router.push('/sales-prospecting')
			}
			
		},
		async returnSP(){
			var res = ''
			const initialPayload = { comment: this.comment}
			const payload = {id: this.$route.params.id, payload: initialPayload}

			res = await this.$store.dispatch('salesProspecting/doReturnSp_Eva', {payload})
			if(res.data.count > 5 && res.status === 200){
				this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Sales Prospect already rejected/returned 5 times','messagetype':'error'},{ root: true });
			}
			else if (res.status === 200) {
				this.$store.commit('app/SET_SNACK_BAR',{'status':true,'message': 'Your work has been successful! ','messagetype':'success'},{ root: true });
				this.$router.push('/sales-prospecting-eval')
			}
		},
		change(payload){
			this.$store.commit('salesProspecting/SET_COMMENT', payload)
		},
		async approve () {
			var res = ''
			const initialPayload = { comment: this.comment}
			const payload = {id: this.$route.params.id, payload: initialPayload}

			if (this.currUser.is_evaluation_committee === '1') {
				await this.$store.dispatch('salesProspecting/doCreateComment_Eva', {payload})
				res = await this.$store.dispatch('salesProspecting/doApproveSPByEval', this.$route.params.id)
			} else if (this.currUser.is_president === '1') {
				await this.$store.dispatch('salesProspecting/doCreateComment_Ceo', {payload})
				res = await this.$store.dispatch('salesProspecting/doApproveSPByPres', this.$route.params.id)
			}
			if (res.status === 200) {
				if (this.currUser.is_evaluation_committee === '1') {
					this.$router.push('/sales-prospecting-eval')
				} else if (this.currUser.is_president === '1') {
					this.$router.push('/sales-prospecting')
				}
			}
		},
		assignData (data) {
			this.decodeUploads(data.sp_primary_details)
			var salesProspectingForm = {
				id: '',
				status: data.status,
				evaluation_status: data.evaluation_status,
				account_name: data.sp_primary_details.account_name,
				legal_entity_name: data.sp_primary_details.legal_entity_name,
				trade_name: data.sp_primary_details.trade_name,
				bp_idno: data.bp_idno,
				scorecard: JSON.parse(data.sp_primary_details.scorecard),
				date_presentation: data.sp_primary_details.date_presentation,
				documents_presented: data.sp_primary_details.documents_presented === null ? [] : JSON.parse(data.sp_primary_details.documents_presented),
				summary_presentation: data.sp_primary_details.summary_presentation,
				accept_or_negotiate: data.sp_primary_details.accept_or_negotiate,
				scorecard_result: data.sp_primary_details.scorecard_result,
				risk_profile: data.sp_primary_details.risk_profile, //Add risk profile data
				uploaded_loi:  data.sp_primary_details.upload_loi === null ? [] : data.sp_primary_details.upload_loi,
				uploaded_picture:  data.sp_primary_details.upload_picture === null ? [] : data.sp_primary_details.upload_picture,
				uploaded_supp_docs:  data.sp_primary_details.upload_supp_docs === null ? [] : data.sp_primary_details.upload_supp_docs,
				uploaded_kaizer: data.sp_primary_details.upload_kaizer === null ? [] : data.sp_primary_details.upload_kaizer, //Add uploaded Kaizer
				uploaded_amla:  data.sp_primary_details.upload_amla === null ? [] : data.sp_primary_details.upload_amla, //Add uploaded AML
				amla_risk_rating: data.sp_primary_details.amla_risk_rating, //Add aml rating data
				amla_risk_profile: data.sp_primary_details.amla_risk_profile //add aml profile data
			}
			this.$store.commit('salesProspecting/SET_SELECTED_SP', salesProspectingForm)
		},
		async save (isDraft) {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};
			let finalPayload =  _.cloneDeep(this.salesProspectingForm);
			finalPayload.scorecard_result = this.computeFinalRating()
			finalPayload.is_draft = isDraft
			var convertedFormData = jsonToFormData(finalPayload, options)
			// finalPayload.scorecard = JSON.stringify(finalPayload.scorecard)
			// finalPayload.upload[0].upload_picture = await this.getExtension(finalPayload.upload[0].upload_picture)
			// finalPayload.upload[0].upload_supp_docs = await this.getExtension(finalPayload.upload[0].upload_supp_docs)
			// finalPayload.upload[0].upload_loi = await this.getExtension(finalPayload.upload[0].upload_loi)
			const payload = {id: this.$route.params.id, payload: convertedFormData}
			const res = await this.$store.dispatch('salesProspecting/doSaveSalesProspecting', {payload})
			if(res.status === 200) {
				this.$router.push('/sales-prospecting');
			}
		},
		computeFinalRating () {
			if(typeof this.salesProspectingForm.scorecard !== 'string'){
				var total = 0
				this.salesProspectingForm.scorecard.forEach(function(e){
					e.criteria.forEach(function(f){
						total += (parseInt(f.score) * (f.weight / f.metrics.length).toFixed(2))
					})
				})
				if(isNaN(total)){
					return ''
				}
				return total.toFixed(2)
			}
		},
		async upload () {
			var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};
			let finalPayload =  _.cloneDeep(this.initialUploaded);
			var convertedFormData = jsonToFormData(finalPayload, options)
			const payload = {id: this.spNumber.id, payload: convertedFormData}
			const res = await this.$store.dispatch('salesProspecting/doInitialUpload', {payload})
			console.log(res)
			
			this.reloadUploads()
		},
		async reloadUploads () {
			const res = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.spNumber.id)
			console.log(res)
			this.decodeUploads(res.data.result[0].sp_primary_details)
			this.$store.commit('salesProspecting/SET_DEFAULT_INITIAL_UPLOADED_FILES')
		},
		decodeUploads (resUpload) {
			console.log(resUpload)
			// resUpload.forEach(function(e) {
				if (resUpload.upload_loi !== null) {
					resUpload.upload_loi = JSON.parse(resUpload.upload_loi)
				}
				if (resUpload.upload_picture !== null) {
					resUpload.upload_picture = JSON.parse(resUpload.upload_picture)
				}
				if (resUpload.upload_supp_docs !== null) {
					resUpload.upload_supp_docs = JSON.parse(resUpload.upload_supp_docs)
				}
				//Decoding for AML and Kaizer
				if (resUpload.upload_amla !== null) {
					resUpload.upload_amla = JSON.parse(resUpload.upload_amla)
				}
				if (resUpload.upload_kaizer !== null) {
					resUpload.upload_kaizer = JSON.parse(resUpload.upload_kaizer)
				}
			this.$store.commit('salesProspecting/SET_EXISTING_INITIAL_UPLOADED_FILES', resUpload)
		},
	}
};
</script>
<style scoped>
.stepper-step {
	cursor: pointer;
}
</style>